import * as React from 'react';
import config from '../../gatsby-config';

type HeaderProps = {
  title?: string | null;
};

const Header = ({ title }: HeaderProps) => (
  <>
    <title>{`${config.siteMetadata.title}${title ? `: ${title}` : ''}`}</title>
    <link rel="icon" href="./favicon.ico" />
  </>
);

export default Header;
