import { Link } from 'gatsby';
import * as React from 'react';

const FooterLinks = () => (
  <div className="footer-links">
    <p>Find out more</p>
    <ul>
      <li>
        <Link to="https://www.linkedin.com/in/carl-willimott-b8735274/">Linkedin</Link>
      </li>
      <li>
        <Link to="https://github.com/carlwillimott">Github</Link>
      </li>
    </ul>
  </div>
);

export default FooterLinks;
