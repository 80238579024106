import * as React from 'react';
import type { HeadFC } from 'gatsby';
import FooterLinks from '../components/FooterLinks';
import config from '../../gatsby-config';
import Header from '../components/Header';
import { Link } from 'gatsby';

const NotFoundPage = () => {
  return (
    <main>
      <div className="intro">
        <h1>{config.siteMetadata.title}</h1>
        <h2>404 - Not Found</h2>
        <Link to="/">Back</Link>
      </div>
      <FooterLinks />
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <Header title="Not Found" />;
